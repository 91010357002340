import React, { useRef } from "react";
import { useTheme } from "@emotion/react";
import { graphql } from "gatsby";

import { Helmet } from "react-helmet";
import { TemplateWrapper } from "./styled";
import useSectionOBserver from "@/hooks/useSectionObserver";
import { setHeaderColors } from "@/theme/base/utils";

function Template({ pageContext, children }: any) {
  const pageRef = useRef(null);
  const currentTheme = useTheme();

  useSectionOBserver(pageRef, setHeaderColors(currentTheme, "light"));

  return (
    <TemplateWrapper ref={pageRef}>
      <Helmet
        defaultTitle="LEAN"
        title={pageContext?.frontmatter?.title}
        titleTemplate="%s | LEAN"
      />

      {children}
    </TemplateWrapper>
  );
}

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`;

export default Template;
