import styled from "@emotion/styled";
import { rectAnimation, sharedRectStyles } from "@/theme/base/utils";

export const ProductPresentationWrapper = styled.section`
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ProductPresentationCotainer = styled.article`
  ${({ theme }) => theme.container}

  padding: 6rem 4rem 26rem;

  > div {
    min-height: 805px;
  }

  header {
    max-width: 860px;
  }

  p {
    max-width: 560px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    margin-top: 8rem;

    > div {
      min-height: auto;
    }
  }

  > div {
    @media only screen and (max-width: 1160px) {
      grid-template-columns: auto;
      margin-bottom: 5rem;
      grid-row-gap: 5rem;
    }
  }
`;

export const ProductPresentationHeader = styled.header``;

export const ProductPresentationFooter = styled.footer`
  margin-top: 8rem;

  @media only screen and (max-width: 1160px) {
    margin-top: 4rem;
  }
`;

export const ProductPresentationRectWrapper = styled.figure`
  width: 100%;
  max-width: 690px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  margin-left: -345px;

  path:first-of-type {
    ${sharedRectStyles}
  }

  .visibleRect path:first-of-type {
    animation: ${rectAnimation} 3s ease-out forwards;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    left: 0;
    margin-left: 0px;
  }
`;
