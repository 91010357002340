import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import Typography from "@/components/Typography";
import ProductPresentation from "@/components/ProductPresentation";
export const _frontmatter = {
  "title": "Para investidores",
  "path": "/para-investidores",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation advantages={["Controle suas posições no mercado", "Compense os prejuízos acumulados", "Calcule o DARF do Imposto de Renda", "Acompanhe os resultados das suas operações", "Declare certo no IRPF", "Múltiplas corretoras", "Ações, ETF, FII, Opções, Termos e Mercado Futuro"]} sideTitle="Investidores" contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.tradertax.com.br/" description="Crie sua conta, envie suas notas, extratos e nós cuidamos de todo o resto. Aprovado por clientes, assessores e professores de investimento" title={() => <>
      Simplifique a{" "}
      <Typography as="span" variant="h3" weight={700} mdxType="Typography">
        apuração
      </Typography>{" "}
      dos seus impostos de bolsa de valores com{" "}
      <Typography as="span" variant="h3" weight={700} mdxType="Typography">
        precisão
      </Typography>{" "}
      e{" "}
      <Typography as="span" variant="h3" weight={700} mdxType="Typography">
        segurança
      </Typography>
      .
    </>} mdxType="ProductPresentation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      