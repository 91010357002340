import React from "react";
import { Link } from "gatsby";
import Reveal from "react-reveal/Reveal";
import Grid from "@/components/Grid";
import {
  ProductPresentationWrapper,
  ProductPresentationCotainer,
  ProductPresentationHeader,
  ProductPresentationFooter,
  ProductPresentationRectWrapper,
} from "./styled";
import Typography from "@/components/Typography";
import Button from "@/components/Button/Button";
import WhatsUpIcon from "@/assets/svg/whatsup.svg";
import ArrowLeftIcon from "@/assets/svg/arrow-left.svg";
import RectIcon from "@/assets/svg/rect.svg";
import PlansBox from "@/components/PlansBox";

interface IProductPresentationProps {
  description: string;
  contactLink: string;
  knowMoreLink: string;
  sideTitle: string;
  fullPrice: string;
  dividedPrice: string;
  dividedTimes: string;
  advantages?: string[];
  title: React.FC;
}

function ProductPresentation({
  title: Title,
  description,
  contactLink,
  knowMoreLink,
  sideTitle,
  fullPrice,
  dividedPrice,
  dividedTimes,
  advantages,
}: IProductPresentationProps) {
  return (
    <ProductPresentationWrapper>
      <ProductPresentationCotainer>
        <Grid rows="1" gap="2rem" columns="auto 38%">
          <div>
            <ProductPresentationHeader>
              <Typography paragraph weight={300} variant="h3" as="h2">
                <Title />
              </Typography>
            </ProductPresentationHeader>

            <Typography>{description}</Typography>

            <ProductPresentationFooter>
              <Button as={Link} to={contactLink}>
                <WhatsUpIcon /> Entrar em contato
              </Button>
              <Button as={Link} to={knowMoreLink} variant="secondary">
                Saiba mais <ArrowLeftIcon />
              </Button>
            </ProductPresentationFooter>
          </div>

          {advantages && Boolean(advantages.length) && (
            <PlansBox
              items={advantages}
              title={sideTitle}
              price={fullPrice}
              dividedPrice={dividedPrice}
              dividedTimes={dividedTimes}
            />
          )}
        </Grid>
      </ProductPresentationCotainer>

      <ProductPresentationRectWrapper>
        <Reveal effect="visibleRect">
          <RectIcon />
        </Reveal>
      </ProductPresentationRectWrapper>
    </ProductPresentationWrapper>
  );
}

export default ProductPresentation;
