import styled from "@emotion/styled";
import Typography from "../Typography";

export const PlansBoxWrapper = styled.aside`
  padding: 0 1rem;
  position: relative;
  margin-top: -2rem;
  z-index: 3;
`;

export const PlansBoxHeader = styled.header`
  background: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  padding: 4px 0 4px 15%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.16);
`;

export const PlansBoxContent = styled.div`
  background: #f0f2f4;
  border-radius: 4px;
  padding: 5.5rem 64px 64px;
  box-shadow: -1px 0px 10px rgb(0 0 0 / 25%);

  li p {
    color: ${({ theme }) => theme.colors.box.color};
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    padding: 5rem 1.5rem 1.5rem;

    svg {
      display: none;
    }
  }
`;

export const PlansBoxItem = styled.li`
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid #e1e2e4;
  border-top: 1px solid #e1e2e4;
  display: inline-flex;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.plans.primary};
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    justify-content: center;

    p {
      text-align: center;
    }
  }

  p {
    font-size: 16px;
  }

  svg {
    margin-right: 1rem;
  }
`;

export const PlansBoxPrice = styled(Typography)`
  background: ${({ theme }) => theme.colors.primary};
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
`;
