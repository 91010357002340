import styled from "@emotion/styled";

interface IGridProps {
  columns: string;
  rows: string;
  gap?: string;
}

const Grid = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  grid-template-rows: ${({ rows }) => rows};

  ${({ gap }) => gap && `grid-gap: ${gap}`};
`;

export default Grid;
