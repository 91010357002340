import React from "react";
import Typography from "../Typography";
import CheckIcon from "@/assets/svg/check.svg";

import {
  PlansBoxWrapper,
  PlansBoxHeader,
  PlansBoxContent,
  PlansBoxItem,
  PlansBoxPrice,
} from "./styled";

interface IAboutMEIProps {
  title: string;
  price: string;
  dividedPrice: string;
  dividedTimes: string;
  items: string[];
}

function PlansBox({
  title,
  price,
  dividedPrice,
  dividedTimes,
  items,
}: IAboutMEIProps) {
  return (
    <PlansBoxWrapper>
      <PlansBoxHeader>
        <Typography color="light">{title}</Typography>
      </PlansBoxHeader>

      <PlansBoxContent>
        <ul>
          {items.map((item) => (
            <PlansBoxItem>
              <CheckIcon />

              <Typography>{item}</Typography>
            </PlansBoxItem>
          ))}
        </ul>

        {Boolean(price) && (
          <PlansBoxPrice align="center" color="light" weight={300}>
            {dividedTimes}x de{" "}
            <Typography variant="h4" as="span" color="light">
              R${dividedPrice}
            </Typography>
            <br />
            ou{" "}
            <Typography weight={700} color="light" as="span">
              R${price}
            </Typography>{" "}
            via pix ou boleto
          </PlansBoxPrice>
        )}
      </PlansBoxContent>
    </PlansBoxWrapper>
  );
}

export default PlansBox;
