import { useEffect } from "react";

// TODO: Move the handlers to a queue in order to avoid concurrency problems

const useSectionOBserver = (sectionRef: any, onIntersect: (root) => void) => {
  useEffect(() => {
    if (!sectionRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const root = document.querySelector(":root") as HTMLElement;

        if (entry.isIntersecting) {
          onIntersect(root);
        }
      });
    });

    observer.observe(sectionRef.current as unknown as HTMLDivElement);
  }, []);
};

export default useSectionOBserver;
